import { Controller } from "@hotwired/stimulus"
import TWzipcode from "libs/twzipcode/twzipcode.js"

// Connects to data-controller="consumer-form"
export default class extends Controller {

  static targets = [
    "form", "step1", "step2", "submitButton", "twzipcode", "zipcode",
    "fullname", "mobile", "birthday", "gender", "email", "address",
    "fullnameDisplay", "mobileDisplay", "birthdayDisplay", "genderDisplay", "emailDisplay", "addressDisplay"
  ]

  twzipcode = null

  connect() {
    // 使用 twzipcode 來產生地區選單
    this.twzipcode = new TWzipcode();

    // 因 twzipcode 為額外套件，如果有選擇過地區，則在 form 表單重新載入時手動設定郵遞區號，防止選過的地區消失
    if ( this.hasZipcodeTarget ) {
      this.twzipcode.set(this.zipcodeTarget.value)
    }

    // 因為 twzipcode set 後會延遲 10 ms，所以延遲顯示
    setTimeout(() => {
      this.displayInfo()
    }, 50)
  }

  validateForm(event) {
    event.preventDefault()
    console.log(this.twzipcode.get())
    this.formTarget.requestSubmit()
  }

  displayInfo() {
    if ( this.hasStep2Target === false ) {
      return
    }

    // 姓名
    this.fullnameDisplayTarget.textContent = this.fullnameTarget.value
    // 手機
    this.mobileDisplayTarget.textContent = this.mobileTarget.value
    // 生日
    this.birthdayDisplayTarget.textContent = this.birthdayTarget.value
    // 性別
    const genderValue = this.genderTarget.querySelector('input[type="radio"]:checked')?.value
    if ( genderValue === "1" ) {
      this.genderDisplayTarget.textContent = "男"
    } else if ( genderValue === "2" ) {
      this.genderDisplayTarget.textContent = "女"
    }
    // Email
    this.emailDisplayTarget.textContent = this.emailTarget.value
    // 地址
    const zipData = this.twzipcode.get()
    let address = `${zipData.county} ${zipData.district} ${this.addressTarget.value}`
    this.addressDisplayTarget.textContent = address
  }

  // 返回修改
  prevStep() {
    // 顯示會員註冊 form
    this.step1Target.style.display = "block";
    // 刪除會員條款
    this.step2Target.remove()
  }

  // 會員條款勾選才能送出
  agreementCheck(event) {
    if ( event.target.checked ) {
      this.submitButtonTarget.removeAttribute("disabled")
    } else {
      this.submitButtonTarget.setAttribute("disabled", "disabled")
    }
  }
}
